import {useLazyLogoutUserQuery} from "../../features/api/safestudent/user.endpoint";
import {useNavigate} from "react-router";
import {useAppDispatch} from "../../store/hooks";
import {setLoggedIn} from "../../features/auth/auth.slice";

const useLogin = () => {
    const [logout, {isLoading, isError, isSuccess}] = useLazyLogoutUserQuery();
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const logoutUser = () => {
        logout(false).then((logoutResponse) => {
            if (logoutResponse.isSuccess) {
                dispatch(setLoggedIn(false))
                navigate('/login')
                localStorage.setItem('loggedIn', 'false')
                window.location.reload()
            }
        });
    }

    return {logoutUser, isLoading, isSuccess, isError} as const
}

export default useLogin;