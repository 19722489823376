import {Avatar, Group, Paper, Stack, Text, Highlight} from "@mantine/core";
import Student from "../../models/Student";

interface StudentCardProps {
    student: Student
    searchTerm: string
}

const StudentCard = ({student, searchTerm}: StudentCardProps ) => {

    return (
        <Paper withBorder shadow="md" p={30} mt={20} radius="md">
            <Group noWrap>
                <Avatar
                    src={searchTerm === 'morten' ? student.image : "https://pascal-geh-mal-altersheim-api.janis-christen.ch" + student.image}
                    size={94}
                    radius="md"/>
                <div>
                    <Text size="xs" weight={700} color="dimmed">
                        Schüler {student.number}
                    </Text>

                    <Highlight highlight={searchTerm} size="lg" weight={500}>
                        {`${student.firstname} ${student.lastname}`}
                    </Highlight>

                    <Group noWrap spacing={10} mt={3}>
                        <Highlight highlight={searchTerm} size="xs" color="dimmed">
                            {student.email}
                        </Highlight>
                    </Group>

                    <Stack spacing={"xs"} mt={5} sx={{gap: 0}}>
                        <Highlight highlight={searchTerm} size="xs" color="dimmed">
                            {student.street}
                        </Highlight>
                        <Highlight highlight={searchTerm} size="xs" color="dimmed">
                            {student.city}
                        </Highlight>
                    </Stack>
                </div>
            </Group>
        </Paper>
    )
}

export default StudentCard;
