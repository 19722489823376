import type {Middleware, MiddlewareAPI} from '@reduxjs/toolkit'
import {isRejectedWithValue} from '@reduxjs/toolkit'
import {setLoggedIn} from "../auth/auth.slice";

/**
 * Log a warning from a failed request
 */
export const unauthorizedRequestMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 401 && action.meta.arg.endpointName !== 'loginUser') {
            api.dispatch(setLoggedIn(false))
            localStorage.setItem('loggedIn', 'false')
        }
    }

    return next(action)
}