import React from "react";
import {TextInput} from "@mantine/core";
import {IconSearch} from "@tabler/icons";

interface SearchBarProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchBar = ({onChange}: SearchBarProps) => {

    return (
        <TextInput
            radius="xl"
            maxLength={100}
            size="md"
            rightSection={
                <IconSearch size={18} stroke={1.5}/>
            }
            placeholder="Schüler suchen..."
            rightSectionWidth={42}
            onChange={(e) => onChange(e)}
        />
    )
}

export default SearchBar;
