import apiSlice from "./api.slice";

const studentApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getStudents: builder.query({
            query: () => `/student/all`,
        }),
    }),
    overrideExisting: false,
})

export const {
    useGetStudentsQuery,
    useLazyGetStudentsQuery
} = studentApi;
