import Student from "../../models/Student";

const useSearch = (searchTerm: string, students: Student[] | undefined) => {
    if (students) {
        if (searchTerm.toLowerCase() === "megaphon") {
            return students.filter((student) => {
                return student.firstname === "Jakob" && student.lastname === "Bertram"
            });
        }
        if (searchTerm.toLowerCase() === "svp") {
            return students.filter((student) => {
                return student.firstname === "Joël" && student.lastname === "Klaus"
            });
        }
        if (searchTerm.toLowerCase() === "npc") {
            return students.filter((student) => {
                return student.firstname === "Pascal" || student.lastname === "Berger"
            });
        }
        if (searchTerm.toLowerCase() === "morten") {
            let tmpArray = [];
            const morten: Student = {
                number: 187,
                firstname: 'Alexander',
                lastname: 'Feller',
                city: '00180 Helsinki',
                street: 'Jätkäsaarenlaituri 1',
                email: 'morten@supercell.com',
                image: 'https://tie-international.com/wp-content/uploads/2022/09/Portrait-Alex.png'
            }
            tmpArray.push(morten)
            return tmpArray;
        }
        return students.filter((student) => {
            let tmpStudent = Object.assign({}, student)
            tmpStudent.firstname = `${student.firstname} ${student.lastname}`;
            return Object.values(tmpStudent).join('').toLowerCase().includes(searchTerm.toLowerCase())
        });
    }
    return []
}

export default useSearch;
