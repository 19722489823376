import {useLazyLoginUserQuery} from "../../features/api/safestudent/user.endpoint";
import {setLoggedIn} from "../../features/auth/auth.slice";
import {useNavigate} from "react-router";
import {useAppDispatch} from "../../store/hooks";
import {useEffect} from "react";

const useLogin = () => {
    const [tryLogin, {isLoading, isError, isSuccess}] = useLazyLoginUserQuery();
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        const previouslyLoggedIn = localStorage.getItem('loggedIn') ?? '';
        if (previouslyLoggedIn === 'true') {
            dispatch(setLoggedIn(true))
            navigate("/");
        }
        // eslint-disable-next-line
    }, [])

    const loginUser = (username: string, password: string, stayLoggedIn: boolean = false) => {

        tryLogin({username, password, stayLoggedIn}).then((loginResponse) => {
            if (loginResponse.isError) { return; }
            localStorage.setItem('loggedIn', 'true')
            dispatch(setLoggedIn(true))
            navigate('/')
        });

    }

    return {loginUser, isLoading, isSuccess, isError} as const
}

export default useLogin;
