import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

export const apiSlice = createApi({
    reducerPath: 'api',
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://pascal-geh-mal-altersheim-api.janis-christen.ch/api',
        prepareHeaders: (headers) => {
            if(!headers.has('content-type')) {
                headers.set('content-type', 'json')
            }
            return headers;
        },
        credentials: "include"
    }),

    endpoints: (builder) => ({}),
})


export default apiSlice;
