import {
    Alert,
    Button,
    Checkbox,
    Container,
    Group,
    Paper,
    PasswordInput,
    TextInput,
    Title,
    Transition
} from "@mantine/core"
import useLogin from "../../hooks/useLogin/useLogin";
import {useForm} from "@mantine/form";

const Login = () => {
    const {loginUser, isLoading, isError} = useLogin();

    const loginForm = useForm({
        initialValues: {
            username: '',
            password: '',
            keepLogin: false,
        },

        validate: {
            username: (value) => (value.trim().length > 0 ? null : 'Bitte gib einen Benutzernamen ein'),
            password: (value) => (value.trim().length > 0 ? null : 'Bitte gib ein Passwort ein'),
        },
    });

    return (
        <Container size={500} my={40}>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md">
                <Title mb={"lg"} align="center">
                    SafeStudent
                </Title>
                <Transition mounted={isError} transition={"slide-down"} duration={200} timingFunction={"ease"}>
                    {(styles) => <Alert data-cy={"login-error"} style={styles} mb={"lg"} title="Login fehlgeschlagen" color="red">
                        Der angegebene Benutzername oder das Passwort ist ungültig
                    </Alert>}
                </Transition>
                <form
                    onSubmit={loginForm.onSubmit((values) => loginUser(values.username, values.password, values.keepLogin))}>
                    <TextInput disabled={isLoading} label="Benutzername" placeholder="user123" autoComplete={'username'}
                               required {...loginForm.getInputProps('username')}/>
                    <PasswordInput disabled={isLoading} label={"Passwort"} placeholder="Passwort"
                                   autoComplete={'current-password'}
                                   required mt="md" {...loginForm.getInputProps('password')}/>
                    <Group position="apart" mt="lg">
                        <Checkbox disabled={isLoading} label="Eingeloggt bleiben"
                                  sx={{lineHeight: 1}} {...loginForm.getInputProps('keepLogin', {type: "checkbox"})}/>
                    </Group>
                    <Button loading={isLoading} type={"submit"} fullWidth mt="xl">
                        Login
                    </Button>
                </form>
            </Paper>
        </Container>
    )
}

export default Login;
