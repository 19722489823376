import apiSlice from "./api.slice";

const userApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.query({
            query: ({username, password, stayLoggedIn}) => ({
                url: `/user/login`,
                method: 'POST',
                body: JSON.stringify({
                    "username": username,
                    "password": password,
                    "persist": stayLoggedIn
                }),
            })
        }),
        logoutUser: builder.query({
            query: () => ({
                url: `/user/logout`,
                method: 'GET',
            }),
        })
    }),
    overrideExisting: false,
})

export const {
    useLazyLoginUserQuery,
    useLazyLogoutUserQuery
} = userApi;