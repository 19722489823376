import {
    Text,
    Container,
    Title,
    Stack,
    Grid,
    Button,
    Center,
    Loader
} from "@mantine/core"
import {useGetStudentsQuery} from "../../features/api/safestudent/student.endpoint";
import {IconDoorExit, IconX, IconZoomQuestion} from "@tabler/icons";
import {useDebouncedState} from "@mantine/hooks";
import useSearch from "../../hooks/useSearch/useSearch";
import useLogout from "../../hooks/useLogout/useLogout";
import StudentCard from "../../components/StudentCard/StudentCard";
import SearchBar from "../../components/SearchBar/SearchBar";

const Overview = () => {
    const {data, isSuccess, isError, isLoading} = useGetStudentsQuery(false);
    const {logoutUser, isLoading: isLoggingOut, isError: logoutFailed} = useLogout()
    const [searchTerm, setSearchTerm] = useDebouncedState('', 100);
    const filteredStudents = useSearch(searchTerm, data?.students);
    return (
        <Container my={40}>
            <Title align="center" variant={"gradient"}>
                Übersicht
            </Title>
            <Grid mt={"md"}>
                <Grid.Col sm={10}>
                    <SearchBar onChange={(e) => setSearchTerm(e.currentTarget.value)}/>
                </Grid.Col>
                <Grid.Col sm={2}>
                    <Button onClick={() => logoutUser()} loading={isLoggingOut} fullWidth size={"md"}
                            color={logoutFailed ? 'red' : 'blue'} radius={"xl"}
                            leftIcon={logoutFailed ? <IconX size={20}/> : <IconDoorExit size={20}/>}>
                        Logout
                    </Button>
                </Grid.Col>
                {isLoading &&
                    <Grid.Col xs={12}>
                        <Center sx={{height: '20vh'}}>
                            <Loader/>
                        </Center>
                    </Grid.Col>
                }
                {isSuccess && filteredStudents.map((student) =>
                    <Grid.Col sm={6} key={student.number} >
                        <StudentCard searchTerm={searchTerm} student={student}/>
                    </Grid.Col>
                )}
                {(!isLoading && !isError) && filteredStudents.length === 0 &&
                    <Grid.Col xs={12}>
                        <Center sx={{height: '20vh'}}>
                            <Stack align={"center"}>
                                <IconZoomQuestion size={64}/>
                                <Text align={"center"} size={"lg"}>Deine Suche nach <Text span weight={"bold"}>{searchTerm}</Text> lieferte keine Ergebnisse</Text>
                            </Stack>
                        </Center>
                    </Grid.Col>
                }
            </Grid>
        </Container>
    )
}

export default Overview;
