import {createSlice, PayloadAction,} from '@reduxjs/toolkit';
import type {RootState} from '../../store/store';

// declaring the types for our state
export type AuthState = {
    loggedIn: boolean | null
};

const initialState: AuthState = {
    loggedIn: localStorage.getItem("loggedIn") === 'true',
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoggedIn: (state, action: PayloadAction<boolean | null>) => {
            state.loggedIn = action.payload;
        },
    },
});
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
    setLoggedIn
} = authSlice.actions;

// calling the above actions would be useless if we could not access the data in the state. So, we use something called a selector which allows us to select a value from the state.
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;

// exporting the reducer here, as we need to add this to the store
export default authSlice.reducer;