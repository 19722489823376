import * as React from "react";
import {Route, Routes} from "react-router";
import Overview from "../../pages/Overview/Overview";
import Login from "../../pages/Login/Login";
import AuthGuard from "./AuthGuard";

const CoreRouter = () => {

    return (
        <Routes>
            <Route path={"/"} element={
                <AuthGuard>
                    <Overview/>
                </AuthGuard>
            }/>
            <Route path={"/login"} element={
                <Login/>
            }/>
        </Routes>
    )
}

export default CoreRouter;
