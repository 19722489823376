import {Navigate} from "react-router";
import React, {ReactElement} from "react";
import {selectLoggedIn, setLoggedIn} from "../auth/auth.slice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";

interface AuthGuardProps {
    children: ReactElement<any, any>
}


const AuthGuard = ({children}: AuthGuardProps) => {
    const dispatch = useAppDispatch();
    const isLoggedIn = useAppSelector(selectLoggedIn)
    const previouslyLoggedIn = localStorage.getItem('loggedIn') ?? '';

    if (!isLoggedIn && previouslyLoggedIn !== 'true') {
        return (<Navigate to={"/login"}/>)
    } else {
        if (!isLoggedIn && previouslyLoggedIn === 'false') {
            dispatch(setLoggedIn(true));
        }
        return children ? children : <></>;
    }
}

export default AuthGuard;
