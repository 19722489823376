import {Action, configureStore, ThunkAction,} from '@reduxjs/toolkit';
import {setupListeners} from "@reduxjs/toolkit/query";
import {unauthorizedRequestMiddleware} from "../features/middleware/UnauthorizedRequest";
import apiSlice from "../features/api/safestudent/api.slice";
import {authSlice} from "../features/auth/auth.slice";

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        [apiSlice.reducerPath]: apiSlice.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([apiSlice.middleware, unauthorizedRequestMiddleware])
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;