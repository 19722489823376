import React from 'react';
import './App.css';
import CoreRouter from "./features/routing/CoreRouter";

function App() {
    return (
        <CoreRouter/>
    );
}

export default App;
